<template>
  <v-container id="logout" fluid tag="section" class="pa-10">
    <v-progress-linear color="primary" indeterminate rounded height="10" />
  </v-container>
</template>

<script>
  import { Logger } from '@/services/common/Logger';

  export default {
    name: 'Logout',
    async mounted () {
      try {
        await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'logout', {});
      } catch (error) {
        Logger.exception(error);
      }

      this.$store.commit('SET_TOKEN', undefined);
      this.$store.commit('SET_USER', undefined);
      this.$store.commit('SET_LOCATION', undefined);

      await this.$router.push({ name: 'base' });
    }
  };
</script>
